export const ADD_MAIN_CATEGORY = "ADD_MAIN_CATEGORY";
export const DELETE_MAIN_CATEGORY = "DELETE_MAIN_CATEGORY";
export const EDIT_MAIN_CATEGORY = "EDIT_MAIN_CATEGORY";
export const GET_MAIN_CATEGORY_LIST = "GET_MAIN_CATEGORY_LIST";
export const SET_MAIN_CATEGORY_LIST = "SET_MAIN_CATEGORY";

export const ADD_SUB_CATEGORY = "ADD_SUB_CATEGORY";
export const DELETE_SUB_CATEGORY = "DELETE_SUB_CATEGORY";
export const EDIT_SUB_CATEGORY = "EDIT_SUB_CATEGORY";
export const GET_SUB_CATEGORY_LIST = "GET_SUB_CATEGORY_LIST";
export const SET_SUB_CATEGORY_LIST = "SET_SUB_CATEGORY";
