import React from "react";
import { FadeLoader } from "react-spinners";

function Loader({ height }) {
  return (
    <>
      <div
        id="myLoader"
        style={{
          minHeight: height ? height : "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="d-flex align-items-center justify-content-center"
      >
        <FadeLoader color="#13368a" />
      </div>
    </>
  );
}

export default Loader;
