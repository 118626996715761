import swal from "sweetalert";
import { formatError, login } from "../../services/AuthService";
import { setLocaleStorageItem } from "../../globle/utils/localeStorage";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const NAVTOGGLE = "NAVTOGGLE";

export function Logout(navigate, whereToNavigate) {
  localStorage.removeItem("userDetails");
  if (whereToNavigate) {
    navigate(whereToNavigate);
  } else {
    navigate("/login");
  }

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, navigate, setIsLoading) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        setIsLoading(false);
        if (response.data.action) {
          setLocaleStorageItem("userDetails", response.data);

          const data = response.data.userData;

          dispatch(loginConfirmedAction(data));
          navigate("/");
        } else {
          swal("Oops", response.data.message, "error");
        }
      })
      .catch((error) => {
        const errorMessage = formatError(error.message);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}
export function adminLoginConfirmAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
export const navtoggle = () => {
  return {
    type: "NAVTOGGLE",
  };
};
