import axios from "axios";
import swal from "sweetalert";
import { Logout, loginConfirmedAction } from "../store/actions/AuthActions";

import { BASE_URL } from "../globle/services";
import { getLocaleStorageItem } from "../globle/utils/localeStorage";

export function login(email, password) {
  const payload = {
    email,
    password,
  };
  return axios.post(`${BASE_URL}/user/login`, payload, {
    withCredentials: true,
  });
}

export function formatError(errorResponse) {
  switch (errorResponse) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      break;
    case "Email not found!!":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "Incorrect email and password combination !!":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    dispatch(Logout(navigate));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
  const tokenDetailsString = getLocaleStorageItem("userDetails");
  if (!tokenDetailsString) {
    dispatch(Logout(navigate));
    return;
  } else {
    const data = tokenDetailsString.userData;
    dispatch(loginConfirmedAction(data));
  }
}

export function isLogin() {
  const tokenDetailsString = getLocaleStorageItem("userDetails");

  if (tokenDetailsString) {
    return true;
  } else {
    return false;
  }
}
