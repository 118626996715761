import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import ThemeContext from "./context/ThemeContext";
import { ToastContainer } from "react-toastify";
import Loader from "./globle/components/Loader/Loader";
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";
import LoaderContextProvider from "./context/LoaderContext";

const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <ThemeContext>
        <LoaderContextProvider>
          <Suspense fallback={<Loader height={"95vh"} />}>
            <ToastContainer autoClose={6000} placement="top-right" />
            <Routes>
              <Route path="/*" element={<App />}></Route>
            </Routes>
          </Suspense>
        </LoaderContextProvider>
      </ThemeContext>
    </BrowserRouter>
  </Provider>
);
reportWebVitals();
