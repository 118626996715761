import { NAVTOGGLE } from "../actions/AuthActions";

const initialState = {
  posts: [],
};
const initial = false;

function PostsReducer(state = initialState) {
  return state;
}

function toggleMenu(state = initial, action) {
  if (action.type === NAVTOGGLE) {
    return !state;
  }
  return state;
}

export { toggleMenu };
export default PostsReducer;
