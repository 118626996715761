import { SET_ALL_ORDER, UPDATE_ORDER } from "../actions/OrderActions";

const initialState = [];

export default function AllOrderReducer(state = initialState, action) {
  if (action.type === SET_ALL_ORDER) {
    return state.length ? [...state, { ...action.payload }] : [action.payload];
  }
  if (action.type === UPDATE_ORDER) {
    return state.length ? [...state, { ...action.payload }] : [action.payload];
  }
  return state;
}
