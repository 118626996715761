import axios from "axios";
export const COMMON_IMAGE_URL = "https://api.stoneshoppe.in/";

export const BASE_URL = "https://api.stoneshoppe.in/api/admin";
export const FRONT_WEBSITE_URL = "https://stoneshoppe.in/";

const createApiForClient = (contentType) => {
  const headers = { "Cache-Control": "no-cache" };

  if (contentType === "multipart") {
    headers["Content-Type"] = "multipart/form-data";
  }
  if (contentType === "json") {
    headers["Content-Type"] = "application/json";
  }
  const api = axios.create({
    baseURL: BASE_URL,
    timeout: 600000,
    withCredentials: true,
  });

  // Request interceptor
  api.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  // Response interceptor
  api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return api;
};

const apiMultipart = createApiForClient("multipart");
const apiJson = createApiForClient("json");

export { apiMultipart, apiJson };
