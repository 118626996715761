import {
  ADD_MAIN_CATEGORY,
  ADD_SUB_CATEGORY,
  DELETE_MAIN_CATEGORY,
  DELETE_SUB_CATEGORY,
  EDIT_MAIN_CATEGORY,
  EDIT_SUB_CATEGORY,
  GET_MAIN_CATEGORY_LIST,
  GET_SUB_CATEGORY_LIST,
  SET_MAIN_CATEGORY_LIST,
  SET_SUB_CATEGORY_LIST,
} from "../actions/CategoryActions";

const initialState = {
  mainCategory: null,
  subCategory: null,
};

export default function CategoryReducer(state = initialState, action) {
  if (action.type === ADD_MAIN_CATEGORY) {
    return {
      ...state,
      mainCategory: [action.payload, ...state.mainCategory],
    };
  }
  if (action.type === EDIT_MAIN_CATEGORY) {
    const updatedData = state.mainCategory.map((item) => {
      if (item.id === action.payload.id) {
        return action.payload;
      } else return item;
    });
    return {
      ...state,
      mainCategory: updatedData,
    };
  }
  if (action.type === DELETE_MAIN_CATEGORY) {
    const updatedData = state.mainCategory.filter(
      (item) => item.id !== action.payload.id
    );
    return {
      ...state,
      mainCategory: updatedData,
    };
  }
  if (action.type === GET_MAIN_CATEGORY_LIST) {
    return state;
  }
  if (action.type === SET_MAIN_CATEGORY_LIST) {
    return {
      ...state,
      mainCategory: action.payload,
    };
  }

  //  FOR SUB CATEGORY
  if (action.type === ADD_SUB_CATEGORY) {
    return {
      ...state,
      subCategory: [...state.subCategory, action.payload],
    };
  }
  if (action.type === EDIT_SUB_CATEGORY) {
    const updatedData = state.subCategory.map((item) => {
      if (item.id === action.payload.id) {
        return action.payload;
      } else return item;
    });
    return {
      ...state,
      subCategory: updatedData,
    };
  }
  if (action.type === DELETE_SUB_CATEGORY) {
    const updatedData = state.subCategory.filter(
      (item) => item.id !== action.payload.id
    );
    return {
      ...state,
      subCategory: updatedData,
    };
  }
  if (action.type === GET_SUB_CATEGORY_LIST) {
    return state;
  }
  if (action.type === SET_SUB_CATEGORY_LIST) {
    return {
      ...state,
      subCategory: action.payload,
    };
  }
  return state;
}
