import { createContext, useEffect, useState } from "react";

export const LoaderContext = createContext();

export default function LoaderContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      document.querySelector("body").style.overflow = "hidden";
    } else document.querySelector("body").style.overflow = "auto";
  }, [isLoading]);

  return (
    <LoaderContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </LoaderContext.Provider>
  );
}
